.app__intro {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 4rem;
  padding: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }

  .app__intro-img  {
    position: relative;

    .app__into-img--adil {
      position: relative;
      z-index: 1;
      filter: grayscale(.5);
      width: 400px;
      transition: all .1s ease-in-out;

      &:hover {
        filter: grayscale(0);
      }

      @media (max-width: 768px) {
        width: 280px;
      }
    }

    .app__into-img--circle {
      width: 300px;
      position: absolute;
      top: 50%;
      left: 40%;

      @media (max-width: 768px) {
        width: 215px;
      }
    }
  }

  .app__intro-text {
    font-size: 1.4rem;
  }
}